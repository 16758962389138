import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section id="contact-info" className="bg-dark">
      <div className="container">
        <div className="box ">
          <i className="fas fa-hotel fa-3x"></i>
          <h3> Location</h3>
          <p>Butwal-9, New Bus Park, Rupandehi, Nepal</p>
        </div>
        <div className="box">
          <i className="fas fa-phone-volume fa-3x"></i>
          <h3>Phone Number</h3>
          <p>
            <Link to="tel:071545150" className="call-item">
              (071) 545150
            </Link>
          </p>
          <p>
            <Link to="tel:+9779868831676" className="call-item">
              (+977) 9868831676
            </Link>
          </p>
          <p>
            <Link to="tel:+9779847306449" className="call-item">
              (+977) 9847306449
            </Link>
          </p>
        </div>
        <div className="box ">
          <i className="fas fa-envelope fa-3x"></i>
          <h3>Email Address</h3>
          <p>
            <Link
              to="mailto:hotelnamobuddha123@gmail.com"
              className="mail-item"
            >
              hotelnamobuddha123
              <br />
              @gmail.com
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
