import React from "react";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <>
      <div id="showcase">
        <div className="container">
          <div className="showcase-content">
            <h1>
              <span className="text-primary">Enjoy</span> your stay
            </h1>
            <p className="lead">Welcome to Hotel Namo Buddha International</p>
            <Link className="btn" to="/about">
              About Us
            </Link>
          </div>
        </div>
      </div>
      <section id="home-info" className="bg-dark">
        <div className="info-img"></div>
        <div className="info-content">
          <h2>
            <span className="text-primary">History</span> of our Hotel
          </h2>
          <p className="hotel-desc-index">
            Welcome to the Namo Buddha International Hotel, a cozy and
            comfortable hotel located in the heart of the Butwal city. Today,
            the Hotel Namo Buddha remains committed to providing affordable,
            comfortable accommodations for travelers from all walks of life.
            We're happy for your services and the many guests who have made our
            hotel their home away from home over the years, and we look forward
            to welcoming you to our community. Thank you for contribution
            towards our services.
          </p>
          <Link to="/about" className="btn btn-light">
            Read More
          </Link>
        </div>
      </section>
      <footer id="main-footer"></footer>
    </>
  );
};

export default Index;
