import React from "react";
import { Link } from "react-router-dom";
import imgLogo from "../img/img-hotel/hotel-logo.png";

const Navbar = () => {
  return (
    <>
      <nav id="navbar">
        <div className="container">
          <h1 className="logo">
            <Link to="/">
              <img src={imgLogo} alt="ImgLogo" className="img-logo" />
            </Link>
          </h1>
          <ul className="link-navbar">
            <li>
              <Link
                className="current-link"
                to="/"
                style={{ color: "#f7c08a" }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link to="/about">
                About <span className="navlink-us">Us</span>
              </Link>
            </li>
            <li>
              <Link to="/service">Services</Link>
            </li>
            <li>
              <Link to="/contact">
                Contact <span className="navlink-us">Us</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
