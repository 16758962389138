import React from "react";
import Photo2 from "../img/img-hotel/hotel-round-img.jpg";

const About = () => {
  return (
    <>
      <section id="about-info" className="bg-light py-3">
        <h1 className="l-heading">
          {" "}
          <span className="text-primary">About</span> our Hotel{" "}
        </h1>
        <div className="container">
          <div className="info-left">
            <p className="hotel-about-desc">
              Welcome to the Hotel Namo Buddha, where hospitality meets
              affordability. Our hotel is dedicated to providing guests with a
              comfortable and convenient stay, whether you're traveling for
              business or pleasure.
            </p>
            <p className="hotel-about-desc">
              In addition to our exceptional service, the Hotel Namo Buddha
              offers a range of amenities designed to make your stay as
              comfortable as possible. Each of our rooms comes equipped with
              plush bedding, a flat-screen TV, and free Wi-Fi, and our
              complimentary breakfast ensures that you start your day off right.
            </p>
            <p className="hotel-about-desc">
              Whether you're visiting for a weekend getaway or an extended
              business trip, we're confident that you'll find the Hotel Namo
              Buddha to be your home away from home. We can't wait to welcome
              you to our hotel!
            </p>
          </div>
          <div className="info-right">
            <img src={Photo2} alt="Hotel" className="img-photo-right" />
          </div>
        </div>
      </section>
      <div className="clr"></div>
    </>
  );
};

export default About;
