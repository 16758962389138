import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.target;
    const endpoint = `https://formspree.io/f/xlekzjqw`;

    try {
      const formData = new FormData(form);
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Accept": "application/json",
        },
        body: formData,
      });
      await response.json();
      alert("Message sent successfully!");
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error(error);
      alert("Server Error. Please try again later");
    }
  };

  return (
    <>
      <section id="contact-form" className="py-3">
        <div className="container">
          <h1 className="l-heading">
            <span className="text-primary">Contact </span>Us
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label for="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label for="message">Message</label>
              <textarea
                type="email"
                name="message"
                id="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn">
              Submit
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
