import React from "react";

const Services = () => {
  return (
    <>
      <section id="services">
        <h1 className="services-head">
          Our <span className="text-primary">Services</span>
        </h1>
        <div className="services-part">
          <div className="services-part-first">
            <i
              className="fas fa-wallet"
              style={{ color: "#333", marginRight: "1rem" }}
            ></i>{" "}
            <span className="service-tag">A/C Room, Non A/C Room</span> <br />
            <p className="service-part-para">
              Our hotel offers a variety of rooms to suit every traveler's
              needs, including air-conditioned and non-air-conditioned rooms.
              Our A/C rooms are perfect for those who prefer a cooler
              environment, while our non-A/C rooms offer a more natural and
              refreshing breeze. Each of our rooms is equipped with comfortable
              beds, fresh linens, and all the necessary amenities to make your
              stay as comfortable as possible.
            </p>
          </div>
          <div className="services-part-third">
            <i
              className="fas fa-igloo"
              style={{ color: "#333", marginRight: "1rem" }}
            ></i>{" "}
            <span className="service-tag">Party & Seminar Hall</span> <br />
            <p className="service-part-para">
              Whether you're planning a business conference or a personal
              celebration, our hotel's party and seminar hall is the perfect
              venue for your event. With a spacious, well-equipped room and a
              team of experienced staff, we can help you plan and execute an
              unforgettable event. Our hall is suitable for a variety of events,
              including seminars, workshops, birthday parties, and weddings.
            </p>
          </div>
          <div className="services-part-fourth">
            <i
              class="fas fa-life-ring"
              style={{ color: "#333", marginRight: "1rem" }}
            ></i>{" "}
            <span className="service-tag">Live Bar & Cottage</span> <br />
            <p className="service-part-para">
              Our hotel's live bar is the perfect place to unwind and enjoy a
              delicious drink after a long day of sightseeing or business
              meetings. We offer a wide selection of alcoholic and non-alcoholic
              beverages, as well as a variety of tasty snacks to complement your
              drink. Additionally, our hotel's cottage accommodations provide a
              relaxing and private setting for guests who prefer more space and
              privacy during their stay.
            </p>
          </div>
          <div className="services-part-second">
            <i
              class="fas fa-parking"
              style={{ color: "#333", marginRight: "1rem" }}
            ></i>{" "}
            <span className="service-tag">Parking Available</span> <br />
            <p className="service-part-para">
              Hotel Namo Buddha offers 24-hour indoor parking for its guests.
              The hotel is directly accessible from the indoor car park and
              guests can easily reach the shopping mall by using the elevators
              that are facing the car park hotel entrance. The indoor parking
              and valet services are free for accommodating hotel guests.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
